import CheckoutService from "@services/CheckoutService";
import { actions as checkOutActions } from "@store/checkout/reducer";
import { UiAction } from "@store/ui/reducer";
import { actions as OrderActions } from "@store/order/reducer";

const {
  startLoading,
  updateCheckoutOptions,
  updatePaymentMethod,
  updateOrderTotals,
  updateError,
  endLoading,
} = checkOutActions;

const itemIdPair = ({ items, ...payload }) => {
  const newItems = Object.values(items).map((item) => ({
    itemId: item.itemId,
    quantity: item.quantity,
    items: generateModifierPayload(item.selectedModifiers),
  }));

  return { items: newItems, ...payload };
};

export const placeOrder = (payload) => (dispatch) => {
  dispatch(checkOutActions.startOrderSetting());
  return CheckoutService.placeOrder(generatePayload(payload))
    .then((res) => {
      dispatch(checkOutActions.updateOrderDetails(res));
      return res;
    })
    .catch((error) => {
      dispatch(checkOutActions.updateOrderError(error));
      throw error;
    })
    .finally(() => {
      dispatch(checkOutActions.endOrderSetting());
    });
};

const validationErrorsMessage = (e) => {
  const error = e || "Error in fetching order totals";
  const { status } = error;
  console.log(status);
  if (status?.systemMessage === "OutOfStock") {
    return status?.userMessage;
  }

  return status?.userMessage || error;
};

let getOrderReviewRequestInProgress = false;
export const getOrderReview = (payload) => async (dispatch) => {
  dispatch(OrderActions.updateItemLoading(true));
  if (getOrderReviewRequestInProgress) {
    return;
  }
  getOrderReviewRequestInProgress = true;
  dispatch(startLoading());
  try {
    const checkoutOptions = await CheckoutService.getCheckoutOptions(
      payload.checkoutOptions
    );
    const paymentMethod = await CheckoutService.getPaymentMethod(
      payload.paymentMethod
    );
    const orderTotals = await CheckoutService.getOrderTotals(
      itemIdPair(payload.orderTotals)
    );

    dispatch(updateCheckoutOptions(checkoutOptions));
    dispatch(updatePaymentMethod(paymentMethod));
    dispatch(updateOrderTotals(orderTotals));

    return {
      checkoutOptions,
      paymentMethod,
      orderTotals,
    };
  } catch (e) {
    dispatch(updateError(e || "Error in fetching order totals"));
    const errorMessage = validationErrorsMessage(e);
    dispatch(UiAction.setSnackBarError(errorMessage));
    dispatch(updateOrderTotals({ error: errorMessage }));
    throw e;
  } finally {
    getOrderReviewRequestInProgress = false;
    // SetTimeout is used to avoid state update before UI time display update
    setTimeout(() => dispatch(OrderActions.updateItemLoading(false)), 1500);
    dispatch(endLoading());
  }
};

export const generatePayload = ({
  outlet,
  tipsAmount,
  comment,
  locationId,
  items,
  orderTypeId,
  totalPaid,
  ...otherParams
}) => {
  const { outletId } = outlet;
  const itemsPayload = generateItemsPayload(items);

  if (tipsAmount && tipsAmount > 0) {
    itemsPayload.push({
      itemId: -1,
      quantity: 1,
      unitPrice: tipsAmount,
    });
  }

  return {
    outletId,
    orderTypeId,
    comments: comment,
    locationId,
    totalPaid,
    items: itemsPayload,
    ...otherParams,
  };
};

const generateItemsPayload = (items) => {
  return Object.values(items).map((item) => {
    return {
      itemId: item.itemId,
      quantity: item.quantity,
      comments: item.comment,
      items: generateModifierPayload(item?.selectedModifiers),
    };
  });
};

const generateModifierPayload = (modifiers = []) => {
  return modifiers.map((modifier) => ({
    itemId: modifier.itemId,
    quantity: 1,
  }));
};
