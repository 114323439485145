import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdyenCheckout from "@adyen/adyen-web";
import { Typography, Grid, Box } from "@mui/material";
import Loading from "@components/loading";
import QueryString from "query-string";

import "@adyen/adyen-web/dist/adyen.css";
import "./style.css";
import { createOperationLog } from "@root/store/payment/api";

function AdyenElements({
  totalPayment,
  totalAmount,
  notifyError,
  startOrder,
  adyenConfiguration,
  getAdyenCheckoutConfiguration,
  setLoading,
  handleRedirect,
  ...props
}) {
  // Handling of redirect condition
  // https://docs.adyen.com/online-payments/web-components#handleredirectresult

  const queryParams = QueryString.parse(window.location.search);
  const { sessionId, redirectResult } = queryParams;
  const dispatch = useDispatch();

  // End of redirect condition

  // Hooks initialization
  const paymentContainer = useRef(null);
  const outlet = useSelector((state) => state.outlet);
  // End  Hooks initialization

  const getAdyenCheckout = async (val) => {
    console.log("Into getAdyenCheckout");
    const configuration = getAdyenCheckoutConfiguration();
    console.log("getAdyenCheckout configuration=>", { queryParams });

    if (Object.keys(adyenConfiguration).length) {
      const checkout = await AdyenCheckout(configuration);
      console.log("getAdyenCheckout configuration=>", { checkout });
      if (paymentContainer.current) {
        checkout.create("dropin").mount(paymentContainer.current);
      }
    }
  };

  useEffect(() => getAdyenCheckout(), []);
  useEffect(
    () => handleRedirect({ sessionId, redirectResult }),
    [redirectResult]
  );

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="subTitle4">Please enter card details</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box position="relative">
          <Box position="absolute" height="200px" width="100%" right="0px">
            <Loading height="100%" />
          </Box>
          <Box sx={{ zIndex: 9999, position: "relative", background: "#fff" }}>
            <div id="dropin-container" ref={paymentContainer}></div>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

AdyenElements.propTypes = {};

export default AdyenElements;
