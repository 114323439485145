import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import QueryString from "query-string";
import Loading from "@components/loading";

function AdyenRedirect(props) {
  const [isLoading, setIsLoading] = useState(true);
  const queryParams = QueryString.parse(window.location.search);
  const { sessionId, redirectResult } = queryParams;

  useEffect(
    () => props.handleRedirect({ sessionId, redirectResult }),
    [redirectResult]
  );

  useEffect(() => {
    setTimeout(() => {
      setIsLoading((state) => !state);
    }, 5000);
  }, []);

  if (redirectResult) {
    return null;
  }

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <Typography variant="h3" align="center">
          Please close this tab to complete payment.
        </Typography>
      )}
    </Box>
  );
}

AdyenRedirect.propTypes = { handleRedirect: PropTypes.func };

AdyenRedirect.defaultProps = { handleRedirect: () => {} };

export default AdyenRedirect;
