// https://api.fetchmybill.com/event-log/api/v1/event

import APIService from "./APIService";
import { v4 as uuidv4 } from "uuid";

let uniqueID = `${uuidv4()}-${Date.now()}`;
let sessionId = localStorage.getItem("sessionToken");

export default class LoggingService extends APIService {
  static createLog(params) {
    const extendedParams = {
      time: Date.now(),
      corelationId: uniqueID,
      type: "info",
      sessionId,
      ...params,
    };
    return this.fetch(`${process.env.LOGGING_API_URL}/event-log/api/v1/event`, {
      method: "POST",
      body: JSON.stringify({ ...extendedParams }),
    })
      .then(this.getResponseData)
      .catch(this.getResponseError);
  }
  static createAPILog(message, data) {
    this.createLog({ data: { actionDescription: message, ...data } });
  }
}

export const createLog = (params) => {
  try {
    return LoggingService.createLog(params);
  } catch (e) {
    console.log(e);
  }
};
