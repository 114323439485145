import React from "react";
import PropTypes from "prop-types";
// MUI imports
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

// Components
import Currency from "@components/currency";

function ItemActionButton({
  decreaseQuantity,
  increaseQuantity,
  addItem,
  item,
  isSquareEdges,
}) {
  return (
    <Box
      position="absolute"
      bottom={0}
      width="calc(100% - 32px)"
      p={2}
      zIndex={9999}
      backgroundColor="#fff"
      sx={{ borderTop: "1.5px solid #E0E0E0" }}
    >
      <Grid container justifyContent="space-evenly">
        <Grid item>
          <ButtonGroup
            variant="simple"
            aria-label="outlined primary button group"
            fullWidth
            sx={{
              backgroundColor: "#f2f2f2",
              borderRadius: isSquareEdges ? "1px" : "40px",
              padding: "17.5px 0",
              width: "161px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Typography
              width="40%"
              height="100%"
              variant="h3"
              align="center"
              onClick={decreaseQuantity}
            >
              -
            </Typography>
            <Typography width="20%" variant="h3" align="center">
              {item.quantity}
            </Typography>
            <Typography
              width="40%"
              height="100%"
              variant="h3"
              align="center"
              onClick={increaseQuantity}
            >
              +
            </Typography>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            fullWidth
            onClick={addItem}
            sx={{
              borderRadius: isSquareEdges ? "1px" : "49px",
              textTransform: "unset",
              padding: "17.5px 0",
              width: "161px",
            }}
          >
            <Typography variant="h3">
              Add to cart
              {/* <Currency value={item.totalPrice} /> */}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

ItemActionButton.propTypes = {
  decreaseQuantity: PropTypes.func.isRequired,
  increaseQuantity: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default ItemActionButton;
