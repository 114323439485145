import { createOperationLog } from "@root/store/payment/api";

// For more information on this function refer https://pos8.atlassian.net/browse/FE-4270
export const getDecodedLocationId = ({ locationId, locationData }) => {
  createOperationLog("Called getDecodedLocationId");

  // Convert input character to its equivalent digit eg. a=1, b=2, ..., z=26
  const charToDigit = (char) => {
    return char.toLowerCase().charCodeAt(0) - 96;
  };

  // Convert back to a letter to verify decoding
  const calculateChecksum = (digits) => {
    const sum = digits?.reduce((acc, digit) => acc + digit, 0);
    return String.fromCharCode(sum + 1 + 96);
  };

  // Step 1: Convert all characters except the last into digits using the formula
  const characters = locationId?.split("") || "";
  const decodedDigits = characters?.slice(0, -1).map((char) => {
    const digit = charToDigit(char);
    return Math.floor((26 - digit) / 2);
  });

  // Step 2: Verify checksum
  const providedChecksum = characters[characters.length - 1];
  const expectedChecksum = calculateChecksum(decodedDigits);

  if (providedChecksum !== expectedChecksum) {
    createOperationLog("Checksum mismatch", {
      responsePayload: { expectedChecksum, providedChecksum, locationId },
    });

    return "";
  }

  const locationIdDecoded = locationData
    ? locationData?.value
    : decodedDigits.join("");

  return locationIdDecoded;
};
