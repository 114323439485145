import { createLog } from "@root/services/LoggingService";
import PaymentService from "@services/PaymentService";
import { actions as paymentActions } from "@store/payment/reducer";

const {
  startLoading,
  updateAdyenConfiguration,
  updateError,
  endLoading,
  updateLogs,
  updateOperationLogs,
} = paymentActions;

export const getAdyenPaymentSession = (payload) => (dispatch) => {
  dispatch(startLoading());
  return PaymentService.getAdyenPaymentSession(payload)
    .then((res) => {
      dispatch(updateAdyenConfiguration(res));
      return res;
    })
    .catch((error) => {
      console.error(error);
      dispatch(updateError(error));
      throw error;
    })
    .finally(() => {
      dispatch(endLoading());
    });
};

export const createOperationLog = (message, data) => (dispatch) => {
  try {
    console.log({ message, data });
    createLog({
      data: { actionDescription: message, ...data },
      appName: "FMO",
    }).then((resp) => {
      dispatch(updateOperationLogs(resp));
    });
  } catch (e) {
    console.log("logging error", e);
  }
};
export const createCriticalLog = (message, data) => (dispatch) => {
  try {
    console.log({ message, data });
    createLog({
      data: { actionDescription: message, ...data },
      type: "critical",
      appName: "FMO",
    }).then((resp) => {
      dispatch(updateLogs(resp));
    });
  } catch (e) {
    console.log("logging error", e);
  }
};
