/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Router, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Box, Container } from "@mui/material";
import { useSelector } from "react-redux";
import Routes from "./route";
import Snackbar from "@components/snackbar";
import { useDispatch } from "react-redux";

import logo from "./logo.svg";
import "./App.css";

export const history = createBrowserHistory();

function App() {
  const dispatch = useDispatch();
  const { snackBarInfo } = useSelector((state) => state.ui);
  const { message, severity } = snackBarInfo;
  const { logs, operationLogs } = useSelector((state) => state.payment);
  const [viewLogs, setViewLogs] = useState(false);

  console.log(viewLogs);

  return (
    <React.Fragment>
      <Box className="portrait" sx={{ bgcolor: "#f2f2f2" }}>
        <Router history={history}>
          <Route path="/" component={Routes} />
        </Router>
      </Box>
      {Boolean(message) && <Snackbar text={message} severity={severity} />}
      {/* {logs && (
        <div
          style={{
            color: "grey",
            display: "flex",
            flexDirection: "column",
            padding: 5,
            backgroundColor: "#f2f2f2",
          }}
        >
          <div
            style={{
              fontSize: 10,
              marginTop: 5,
              marginBottom: 5,
            }}
            onClick={() => setViewLogs(!viewLogs)}
          >
            View logs
          </div>

          {viewLogs && (
            <div style={{ border: "1.25px inset grey" }}>
              <div>Critical logs</div>
              <div>{JSON.stringify(logs)}</div>
              <div>Operation logs</div>
              <div>{JSON.stringify(operationLogs)}</div>
            </div>
          )}
        </div>
      )} */}
    </React.Fragment>
  );
}

export default App;
