import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";

import OutletCoverImage from "@components/outlet-cover-image";
import Menu from "@components/menu";
import Loading from "@components/loading";
import ItemDetailDialog from "@components/item-detail-dialog";
import FloatingButton from "@components/floating-button";
import Categories from "@components/categories";
import OutletUnavailableDialog from "@components/outlet-unavaliable-dialog";

import { getOutlet } from "@store/outlet/api";
import { getMenu } from "@store/menu/api";
import { reset } from "@store/resetAPI";
import { actions as OrderActions } from "@store/order/reducer";
import { setOutletId, getOutletId } from "@services/LocalStorageService";
import { defaultTableNumber } from "@services/LocalStorageService";
import { useInView } from "react-intersection-observer";

import { checkValidOrderType, observerConfig } from "./constants";

import "./styles.css";
import { action as UiActions } from "@store/ui/reducer";
import { PaymentActions } from "@root/store/payment/reducer";
import { createOperationLog } from "@root/store/payment/api";

function MenuPage(props) {
  const dispatch = useDispatch();

  const { menu, selectedTab } = useSelector((state) => state.menu);

  const selectedCategory = menu?.categories[selectedTab];

  const trackScroll = () => {
    try {
      const stickyElement = document.getElementById("sticky");
      if (stickyElement) {
        const { top } = stickyElement.getBoundingClientRect();
        if (top <= 0) {
          document
            .getElementById("menu-container-2")
            .classList.add("sticky-overflow");
        } else {
          document
            .getElementById("menu-container-2")
            .classList.remove("sticky-overflow");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const { position, divRef } = useDivPosition();
  // console.log({ position });
  const { isLoading, error, attributes } = useSelector((state) => state.outlet);
  const { isMenuPageLoading } = useSelector((state) => state.ui);
  const { inView } = useInView(observerConfig);
  const { items: itemSelectedForOrder = {} } = useSelector(
    (state) => state.order
  );

  const prevOutletId = getOutletId();

  const { outletId, orderType, locationId } = useParams();
  const history = useHistory();

  const resetStore = () => {
    //console.log(!Boolean(prevOutletId), prevOutletId);
    if (!Boolean(prevOutletId)) {
      setOutletId(outletId);
      return;
    }
    if (prevOutletId !== outletId) {
      dispatch(reset());
    }
  };

  useEffect(() => {
    resetStore();
    if (outletId && orderType) {
      dispatch(
        createOperationLog("get menu api called", {
          responsePayload: { outletId, orderType },
        })
      );
      dispatch(getOutlet({ outletId })).then(
        checkValidOrderType(history, orderType)
      );
      dispatch(getMenu({ outletId }));
    }
  }, []);

  useEffect(() => {
    if (!!prevOutletId && !!outletId && prevOutletId !== outletId) {
      localStorage.removeItem("outletId");
      dispatch(OrderActions.updateOrderFor(defaultTableNumber));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevOutletId, outletId]);

  useEffect(() => {
    dispatch(UiActions.toggleStickyHeader(!inView));
    // if()
  }, [inView]);

  const orderItemLength = Object.values(itemSelectedForOrder).length;
  const goToViewOrder = () => {
    dispatch(createOperationLog("View your button clicked"));
    let location = `/view-order/outlet/${outletId}/order-type/${orderType}`;

    if (locationId) {
      location += `/location/${locationId}`;
    }

    dispatch(PaymentActions.setCardDeclinedError(false));

    history.push(location);
  };

  if (isLoading || isMenuPageLoading) {
    return <Loading />;
  }

  if (error) {
    return null;
  }

  const deviceHeight = document
    .querySelector("body")
    .getBoundingClientRect().height;
  const deviceWidth = document
    .querySelector("body")
    .getBoundingClientRect().width;
  const tabWidth = 1024;

  console.log("Into to Menu page", !selectedCategory);

  return (
    <Grid
      container
      id="menu-container"
      height="100vh"
      overflow="scroll"
      bgcolor="#f2f2f2"
      overflowY="scroll"
      onScroll={(e) => trackScroll()}
      alignContent="flex-start"
      sx={{ paddingX: deviceWidth > tabWidth ? "7.5%" : "" }}
    >
      <Grid item xs={12} height="300px">
        <OutletCoverImage />
      </Grid>
      <Grid item xs={12} pt={2}>
        <Box id="menu-div">
          <Categories />
          <Menu />
        </Box>
      </Grid>
      <ItemDetailDialog />
      {Boolean(orderItemLength) && (
        <Box
          sx={{
            height: "84px",
            borderTop: "1px solid #E0E0E0",
            maxWidth:
              deviceWidth > tabWidth ? "calc(1024px + 175px)" : deviceWidth,
            width: "100%",
            background: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            bottom: "0",
            ...(deviceWidth > tabWidth && {
              left: "50%",
              transform: "translate(-50%, 10px)",
            }),
          }}
        >
          <FloatingButton onClick={goToViewOrder}>
            <Box
              sx={{
                width: "100%",
                position: "relative",
              }}
            >
              <Box position="absolute" right="-8px" top="-6px">
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    height: "40px",
                    width: "40px",
                    borderRadius: attributes?.squareEdges ? "1px" : "40px",
                    color: "#000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {orderItemLength}
                </Box>
              </Box>
              View your order
            </Box>
          </FloatingButton>
        </Box>
      )}
      <OutletUnavailableDialog />
    </Grid>
  );
}

MenuPage.propTypes = {};

export default MenuPage;
