import React from "react";

import { Grid, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";

function OutletDetail(props) {
  const { name, outletType, attributes } = useSelector((state) => state.outlet);
  const { squareEdges } = attributes;

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          p: 2,
          borderRadius: squareEdges ? "1px" : "0px 0px 24px 24px",
          backgroundColor: "#fff",
        }}
      >
        <Typography variant="h3" gutterBottom>
          {name}
        </Typography>
        <Typography
          variant="subText5"
          sx={{
            textTransform: "capitalize",
            color: "#7C7C7C",
          }}
        >
          {outletType}
        </Typography>
      </Box>
    </Grid>
  );
}

export default OutletDetail;
