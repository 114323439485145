import React, { useState } from "react";
import PropTypes from "prop-types";
import Adyen from "@containers/adyen";
import RoomChargesForm from "@components/room-charges-form";
import { useDispatch, useSelector } from "react-redux";
import { action as UiActions } from "@store/ui/reducer";
import { actions as PaymentActions } from "@store/payment/reducer";
import { Box } from "@mui/material";
import Loading from "@components/loading";
import {
  useParams,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { placeOrder } from "@store/checkout/api";
import CheckoutService from "@services/CheckoutService";
import { orderStatus } from "@store/order-status/api";
import {
  backdropStyle,
  getTotalPayment,
  getTotalAmount,
  decodedLocationId,
} from "./constant";
import { reset } from "@store/resetAPI";
import { actions as orderStatusAction } from "@store/order-status/reducer";
import { createCriticalLog, createOperationLog } from "@root/store/payment/api";
import { errorCode } from "@root/store/order-status/constant";

const roomChargesPath = "/room-charges/outlet/:outletId/order-type/:orderType";

function PaymentHandler(props) {
  const [isLoading, setLoading] = useState(false);

  // All Hooks declared
  const dispatch = useDispatch();
  const history = useHistory();
  const { orderType, locationId } = useParams();
  const { path } = useRouteMatch();

  // All redux selectors
  const {
    orderTotals = {},
    orderDetails,
    orderTypes,
  } = useSelector((state) => state.checkout);
  const [selectedOrderType = {}] = orderTypes.filter(
    ({ orderTypeId }) => orderTypeId == orderType
  );

  const { isDelivery } = selectedOrderType;
  const outlet = useSelector((state) => state.outlet);
  const { tipsAmount, comment, orderFor, items, name, email, checkoutFields } =
    useSelector((state) => state.order);
  const { showTableAndRoomSelector } = useSelector(
    (state) => state.viewOrderPage
  );

  // All de-structured variables
  const { outletId, removeSurcharges } = outlet;
  const { totalPrice } = orderTotals;
  // This will be used by payment method adyen
  const totalPayment = getTotalPayment({ tipsAmount, totalPrice });
  // This will be used by fetch apis
  const totalAmount = getTotalAmount({ tipsAmount, totalPrice });

  const { order = {} } = orderDetails;
  const { orderId } = order;

  const getPayload = () => {
    const payload = {
      outlet,
      tipsAmount,
      comment,
      orderTypeId: parseInt(orderType),
      totalPaid: totalAmount,
      items,
      ...checkoutFields,
    };
    if (isDelivery) {
      payload["locationId"] = orderFor?.value;
    }

    if (Boolean(name)) {
      payload["firstName"] = name;
    }
    if (Boolean(email)) {
      payload["email"] = email;
    }
    if (removeSurcharges) {
      payload["removeSurcharge"] = true;
    }

    return payload;
  };

  const goToConfirmationPage = () => {
    console.log("Into goToConfirmationPage");
    dispatch(
      createOperationLog(
        "redirecting to --> /order-confirmation/outlet/${outletId}/order-type/${orderType}",
        {
          responsePayload: {
            outletId,
            orderType,
          },
        }
      )
    );
    history.push(
      `/order-confirmation/outlet/${outletId}/order-type/${orderType}`
    );
    //  window.location.href = `${window.location.origin}/order-confirmation/outlet/${outletId}/order-type/${orderType}`;
  };

  const goToPreviousPage = () => {
    history.push(`/view-order/outlet/${outletId}/order-type/${orderType}`);
  };

  const startOrder = () => {
    console.log("Into to start order");

    if (locationId && locationId !== decodedLocationId(orderFor)) {
      throw new Error("Invalid locationId");
    }

    const payload = getPayload();
    return dispatch(placeOrder(payload)).catch((err) => {
      console.log("Error in placing order", err);
      dispatch(
        createCriticalLog("Error in placing order", {
          responsePayload: { err, payload },
        })
      );
      notifyError("Unable to place order");
      dispatch(orderStatusAction.updateStatus({ order: { orderId: null } }));
      history.push(
        `/${outletId}/${orderType}${locationId ? `/${locationId}` : ""}`
      );
    });
  };

  const onPaymentFail = async (args) => {
    try {
      const message =
        args?.message || "Incorrect payment details. Please try again ";
      dispatch(createCriticalLog("Payment failed", { message }));
      const passedOrderId = args?.orderId;
      setLoading(false);
      dispatch(PaymentActions.reset());

      if (args.disableOrderStatusUpdate) {
        createOperationLog("Not updating order status as per flag ", {
          disableOrderStatusUpdate: args.disableOrderStatusUpdate,
        });
        setOrderStatus(args.order);
        return notifyError(message);
      }

      const orderStatusResponse = await dispatch(
        orderStatus({
          orderId: passedOrderId || orderId,
          statusTypeName: "FailedPayment",
        })
      );

      return notifyError(message);
    } catch (e) {
      console.log(e);
    }
  };

  const confirmOrderStatus = async (attempt = 1, maxAttempts = 50) => {
    if (attempt > maxAttempts) {
      dispatch(
        createCriticalLog(
          `Exceeded the maximum of ${maxAttempts} attempts. Order validation failed.`
        )
      );
      return Promise.reject(
        `Exceeded the maximum of ${maxAttempts} attempts. Order validation failed.`
      );
    }

    const response = await CheckoutService.getOrderDetails({ orderId });

    const [order = {}] = response?.orders || [];

    console.log(`Attempt ${attempt}: Order valid:`, order?.orderValid);
    dispatch(
      createOperationLog(`Attempt ${attempt}: Order valid:`, {
        responsePayload: { orderValid: order?.orderValid, order },
      })
    );

    if (order?.orderValid && order?.statusId > 2) {
      console.log("Order is valid, stopping the recursion.");
      dispatch(createOperationLog("Order validation succeeded!"));
      return Promise.resolve(order);
    } else {
      console.log(`Order is not valid, retrying... (Attempt ${attempt + 1})`);
      dispatch(
        createOperationLog(
          `Order is not valid, retrying... (Attempt ${attempt + 1})`
        )
      );
      // Recursively call the function if order is not valid, incrementing the attempt count
      return await confirmOrderStatus(attempt + 1, maxAttempts);
    }
  };

  const setOrderStatus = (order) => {
    if (!order) {
      return;
    }
    console.log({ order });
    dispatch(orderStatusAction.updateStatus({ order }));
    if (order.statusName === "Failed to send") {
      throw errorCode.UNABLE_TO_COMMUNICATE_TO_POS;
    }
    dispatch(orderStatusAction.updateOrderPlaced(true));
  };

  const onPaymentSuccess = async (args) => {
    try {
      const { orderId: passedOrderId } = args || {};
      console.log("Into Payment Success");
      console.log({ passedOrderId, orderId });
      dispatch(
        createOperationLog("Payment Success", {
          passedOrderId,
          orderId,
        })
      );

      if (args.disableOrderStatusUpdate) {
        dispatch(
          createOperationLog("Not updating order status as per flag ", {
            responsePayload: {
              disableOrderStatusUpdate: args.disableOrderStatusUpdate,
              order: args.order,
            },
          })
        );
        setOrderStatus(args.order);
        return goToConfirmationPage();
      }

      const orderStatusResponse = await dispatch(
        orderStatus({
          orderId: passedOrderId || orderId,
          statusTypeName: "Paid",
        })
      );
      console.log("onPaymentSuccess then");
      goToConfirmationPage();
      return orderStatusResponse;
    } catch (error) {
      console.log("Error in confirming order status", error);
      dispatch(
        createCriticalLog("Error in confirming order status", {
          error,
        })
      );
      if (error.code === "UNABLE_TO_COMMUNICATE_TO_POS") {
        notifyError(error.message);
        goToPreviousPage();
      }
    } finally {
      setLoading(false);
    }
  };

  const notifyError = (message) => {
    dispatch(UiActions.setSnackBarError(message));
  };

  const setSelectedPaymentMethod = (paymentMethod) => {
    dispatch(PaymentActions.selectedPaymentMethod(paymentMethod));
  };

  console.log("Rendering Payment container =>", totalAmount);

  const childProps = {
    outlet,
    totalPayment: totalPayment,
    totalAmount: totalAmount,
    orderPayload: getPayload(),
    key: totalAmount,
    orderId: orderId,
    goToPreviousPage,
    startOrder: startOrder,
    notifyError: notifyError,
    setLoading: setLoading,
    onPaymentSuccess: onPaymentSuccess,
    onPaymentFail: onPaymentFail,
    confirmOrderStatus,
    setSelectedPaymentMethod,
  };

  return (
    <Box position="relative" className="payment-container-wrapper">
      {React.cloneElement(props.children, {
        ...childProps,
      })}
      {isLoading && (
        <Box sx={backdropStyle}>
          <Loading />
        </Box>
      )}
    </Box>
  );
}

PaymentHandler.propTypes = {};

export default PaymentHandler;
