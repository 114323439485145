import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { IconButton } from "@mui/material";
import MenuFilterDrawer from "@components/menu-filter-drawer";
import Icon from "@components/icons";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Categories(props) {
  const { toggleFilter, selectedTab, handleChange, menu, openFilter } = props;

  console.log({ selectedTab });

  const scrollToCategory = (selectedTab) => {
    try {
      const categoryContainer = document.getElementById(selectedTab);
      if (selectedTab) {
        categoryContainer.scrollIntoView({
          //   behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    } catch (E) {
      console.log(E);
    }
  };

  useEffect(() => {
    scrollToCategory(selectedTab);
  }, [selectedTab]);
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        position: "fixed",
        top: 0,
        zIndex: 999,
      }}
      className="categories"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box>
          <IconButton
            onClick={() => {
              toggleFilter(true);
            }}
          >
            <Icon variant="expandMenu" />
          </IconButton>
        </Box>
        <Box width="calc(100vw - 37px)" maxWidth="calc(100% - 37px)">
          <Tabs
            value={selectedTab}
            variant="scrollable"
            aria-label="scrollable prevent tabs example"
            onChange={handleChange}
            scrollButtons
            allowScrollButtonsMobile
          >
            {menu.categories.reduce((acc, parentCategory) => {
              const items = parentCategory.categories.map((category) => (
                <Tab
                  label={category.name}
                  {...a11yProps(category.categoryId)}
                  key={category.categoryId}
                  value={category.categoryId}
                  // onClick={(e) => scrollToCategory(e, category.categoryId)}
                />
              ));
              return [...acc, ...items];
            }, [])}
          </Tabs>
        </Box>
        <Box
          position="absolute"
          sx={{
            height: "1.5px",
            backgroundColor: "#E0E0E0",
            width: "100%",
            bottom: "0",
          }}
        ></Box>
      </Box>

      <Box sx={{ maxWidth: "580px" }}>
        {openFilter && (
          <MenuFilterDrawer
            title="Menu"
            handleSelectCategory={handleChange}
            onClose={() => {
              toggleFilter(false);
            }}
            menu={menu}
          />
        )}
      </Box>
    </Box>
  );
}

Categories.propTypes = {};

export default React.memo(Categories);
