import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import ItemDetail from "@components/item-detail";
import { useDispatch, useSelector } from "react-redux";
import { actions as MenuActions } from "@store/menu/reducer";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ItemDetailDialog({ variant }) {
  const { selectedItem } = useSelector((state) => state.menu);
  const { attributes } = useSelector((state) => state.outlet);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(MenuActions.updateSelectedItem(null));
  };

  const deviceWidth = document
    .querySelector("body")
    .getBoundingClientRect().width;
  const tabWidth = 1024;

  return (
    <div>
      <Dialog
        fullScreen
        open={Boolean(selectedItem)}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
          ".MuiDialog-paper": {
            width: "100%",
            maxWidth: deviceWidth > tabWidth ? 980 : 580,
            height: "100%",
            marginTop: "0px !important",
          },
        }}
      >
        <ItemDetail
          item={selectedItem}
          handleClose={handleClose}
          variant={variant}
          attributes={attributes}
        />
      </Dialog>
    </div>
  );
}
