import { createOperationLog } from "@root/store/payment/api";
import { formatToCurrency } from "@root/util/currency";
import { getDecodedLocationId } from "@root/util/decodeLocationId";
import { multiply, add } from "mathjs";

export const backdropStyle = {
  top: "-209px",
  left: "-24px",
  position: "absolute",
  zIndex: 999999,
  width: "100vw",
  height: "100vh",
  background: "#9b9a9a75",
};

export const getTotalPayment = ({ totalPrice, tipsAmount }) =>
  parseInt(
    formatToCurrency(
      multiply(
        add(formatToCurrency(totalPrice), formatToCurrency(tipsAmount)),
        100
      )
    )
  );

export const getTotalAmount = ({ totalPrice, tipsAmount }) =>
  formatToCurrency(
    add(formatToCurrency(totalPrice), formatToCurrency(tipsAmount))
  );

export const decodedLocationId = (orderFor) => {
  const decoded =
    typeof orderFor?.value === "number"
      ? orderFor?.value
      : getDecodedLocationId({
          locationData: orderFor,
          locationId: orderFor?.value,
        });

  return decoded;
};
