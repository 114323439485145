import React from "react";
import PropTypes from "prop-types";
import Payment from "@containers/payment";
import Adyen from "./Adyen";

function AdyenRedirectWrapper(props) {
  return (
    <Payment>
      <Adyen redirect={true} />
    </Payment>
  );
}

AdyenRedirectWrapper.propTypes = {};

export default AdyenRedirectWrapper;
