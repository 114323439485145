import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { getAdyenPaymentSession } from "@store/payment/api";
import { useDispatch, useSelector } from "react-redux";
import AdyenCheckout from "@adyen/adyen-web";
import Loading from "@components/loading";
import AdyenElements from "./AdyenElements";
import AdyenRedirect from "./AdyenRedirect";
import { useLocation, useRouteMatch } from "react-router-dom";
import { createOperationLog } from "@root/store/payment/api";

function Adyen(props) {
  const shouldHandleRedirect = useRouteMatch(
    "/payment/outlet/:outletId/order-type/:orderType/adyen-redirect"
  );
  const dispatch = useDispatch();
  const { totalPayment, startOrder } = props;
  const { isLoading, adyenConfiguration = {} } = useSelector(
    (state) => state.payment
  );
  const outlet = useSelector((state) => state.outlet);
  // End  Hooks initialization

  const { outletId } = outlet;

  // Adyen payment initialization
  const init = () => {
    console.log("Getting config for => ", totalPayment);
    if (props.redirect) {
      return;
    }
    dispatch(
      createOperationLog("Adyen getting config for", {
        responsePayload: { totalPayment },
      })
    );
    startOrder().then(({ order }) => {
      const { orderId } = order;
      dispatch(
        createOperationLog("Adyen start order api called", {
          responsePayload: {
            outletId: outletId,
            value: totalPayment,
            returnUrl: window.location.href,
            orderId,
          },
        })
      );

      dispatch(
        getAdyenPaymentSession({
          outletId: outletId,
          value: totalPayment,
          returnUrl: `${window.location.href}/adyen-redirect`,
          orderId,
        })
      );
    });
  };

  const { onPaymentFail, onPaymentSuccess, setLoading } = props;

  const onPaymentComplete = (...args) => {
    console.log("onPaymentComplete=>", { args });
    const { resultCode } = args[0];
    if (resultCode === "Refused") {
      return onPaymentFail(...args).then((res) => props.goToPreviousPage());
    }
    return onPaymentSuccess(...args);
  };

  const getAdyenCheckoutConfiguration = (sessionId) => {
    const { id, sessionData, environment, clientKey } = adyenConfiguration;
    const configuration = {
      environment, // Change to 'live' for the live environment.
      clientKey: clientKey || `${process.env.ADYEN_CLIENT_KEY}`, // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication
      analytics: {
        enabled: false, // Set to false to not send analytics data to Adyen.
      },
      session: {
        id: sessionId || id, // Unique identifier for the payment session.
        sessionData, // The payment session data.
      },
      beforeSubmit: (data, component, { resolve }) => {
        // setLoading(true);
        resolve(data);
        // console.log({ data, resolve });
      },

      onPaymentCompleted: onPaymentComplete,
      onError: (...args) => {
        console.log("OnError => ", args);
      },
      onActionHandled: ({ componentType }) =>
        props.setSelectedPaymentMethod(componentType),
      onChange: (...args) => console.log("onchnage", args),
      // Any payment method specific configuration. Find the configuration specific to each payment method:  https://docs.adyen.com/payment-methods
      // For example, this is 3D Secure configuration for cards:
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          billingAddressRequired: false,
        },
      },
    };

    return configuration;
  };

  const handleRedirect = async ({ redirectResult, sessionId }) => {
    console.log("Into handleRedirect=> ", redirectResult);
    if (redirectResult) {
      setLoading(true);
      const checkout = await AdyenCheckout(
        getAdyenCheckoutConfiguration(sessionId)
      );
      // Submit the redirectResult value you extracted from the returnUrl.
      checkout.submitDetails({ details: { redirectResult: redirectResult } });
    }
  };

  useEffect(() => init(), []);
  // End Adyen payment

  if (isLoading) {
    return <Loading />;
  }
  if (!adyenConfiguration?.id) {
    return <Loading />;
  }
  console.log("Adyen loading for total amount => ", totalPayment);
  console.log({ props });

  if (shouldHandleRedirect) {
    return <AdyenRedirect handleRedirect={handleRedirect} />;
  }
  return (
    <AdyenElements
      {...props}
      adyenConfiguration={adyenConfiguration}
      getAdyenCheckoutConfiguration={getAdyenCheckoutConfiguration}
      setLoading={setLoading}
      handleRedirect={handleRedirect}
    />
  );
}

Adyen.propTypes = {};

export default Adyen;
