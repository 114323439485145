import React from "react";
import PropTypes from "prop-types";
import { Grid, Drawer, Typography, Box, Divider } from "@mui/material";
import FloatingButton from "@components/floating-button";
import Icon from "@components/icons";
import FilterAllergenForm from "@components/filter-allergen-form";

function Filter({ selectFilter, filterOptions, ...props }) {
  const { handleSubmit, handleChange, values, isSquareEdges } = props;
  return (
    <Drawer
      anchor="bottom"
      open
      onClose={() => selectFilter(null)}
      PaperProps={{
        sx: {
          borderRadius: isSquareEdges ? "1 1 0 0" : "24px 24px 0 0",
          padding: "0 16px 16px 16px",
          height: "432px",
        },
      }}
    >
      <Box
        position="relative"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "62px",
              }}
            >
              <Typography variant="h2">Filter by allergen</Typography>
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: isSquareEdges ? "1px" : "40px",
                  bgcolor: "#E0E0E0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => selectFilter(null)}
              >
                <Icon
                  variant="cancel"
                  viewBox="0 0 12 12"
                  style={{ fontSize: "12px" }}
                />
              </Box>
            </Box>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <FilterAllergenForm
              handleChange={handleChange}
              filterOptions={filterOptions}
              values={values}
            />
          </Grid>
        </Grid>
        <FloatingButton
          onClick={() => {
            handleSubmit();
            selectFilter(null);
          }}
        >
          Apply filters
        </FloatingButton>
      </Box>
    </Drawer>
  );
}

Filter.propTypes = {};

export default Filter;
