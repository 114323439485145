import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

function Allergens({
  attributes,
  itemId,
  name,
  price,
  isSquareEdges,
  fullWidth,
}) {
  const [maxWidth, setMaxWidth] = useState(0);

  const addMaxWidth = () => {
    const documentWidth =
      document.getElementsByTagName("body")[0].getBoundingClientRect().width -
      16;
    let maxWidth = documentWidth || 0;
    try {
      const element = document.getElementById(`item-footer-${itemId}`);
      const containerWidth = element.getBoundingClientRect().width - 8;
      maxWidth = containerWidth;

      const priceElementWidth = String(price).length * 8.166;
      maxWidth = containerWidth - priceElementWidth;
      console.log({
        name,
        maxWidth,
        containerWidth,
        priceElementWidth,
        priceLength: String(price).length,
        price: String(price),
      });
    } catch (e) {
      console.log(e);
    }
    setMaxWidth((state) => maxWidth);
  };

  useEffect(() => {
    addMaxWidth();
  }, []);

  if (!attributes?.allergens) {
    return null;
  }

  const baseBoxSize = 16;
  const characterSize = 6.773;
  let totalSize = 27.3;

  const updateWidth = (item) => {
    const itemWidth = item.length * characterSize;
    totalSize += itemWidth;
    return totalSize;
  };

  if (attributes?.allergens && !attributes?.allergens.length) {
    return null;
  }

  return (
    <>
      {attributes?.allergens.map((item) => {
        const totalSize = updateWidth(item.name);
        if (totalSize < maxWidth || fullWidth) {
          return (
            <Box
              component="span"
              sx={{
                backgroundColor: item["background-color"],
                color: item.color,
                padding: "5px 8px",
                fontSize: "12px",
                fontWeight: 500,
                borderRadius: "12px",
              }}
            >
              {item.name}
            </Box>
          );
        } else {
          return null;
        }
      })}
      {totalSize > maxWidth && !fullWidth && (
        <Box
          component="span"
          sx={{
            backgroundColor: "#E0E0E0",
            color: "#000",
            padding: "5px 8px",
            fontSize: "12px",
            fontWeight: 500,
            borderRadius: isSquareEdges ? "1px" : "12px",
          }}
        >
          ...
        </Box>
      )}
    </>
  );
}

Allergens.propTypes = {};

Allergens.defaultProps = {
  maxWidth: 400,
  fullWidth: false,
};

export default Allergens;
