import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PaymentService from "@services/PaymentService";
import { action as UIActions } from "@store/ui/reducer";
import { useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";
import { createCriticalLog, createOperationLog } from "@root/store/payment/api";

let ORDER_ID = null;
function CashPayment({ totalPayment, startOrder, ...props }) {
  const dispatch = useDispatch();

  const onSave = async (formData) => {
    dispatch(createOperationLog("Into cash payment method"));

    try {
      props.setLoading(true);
      const { order } = await startOrder();
      const { orderId } = order;
      ORDER_ID = orderId;
      const CashPaymentModel = {
        CashPaymentModel: {
          value: 0,
        },
      };

      dispatch(
        createOperationLog("calling cash payment api", {
          responsePayload: { order, orderId, CashPaymentModel },
        })
      );

      const { success } = await PaymentService.cashPayment({
        ...CashPaymentModel,
        orderId,
      });
      console.log("Finished Cash API");

      if (success === true) {
        dispatch(createOperationLog("Cash api success"));
        await props.onPaymentSuccess({
          orderId: ORDER_ID,
        });
      } else {
        dispatch(
          createCriticalLog("Unable to make payment.Please try again later", {
            responsePayload: { orderId: ORDER_ID },
          })
        );
        throw new Error("Unable to make payment.Please try again later");
      }
      console.log("Outside Cash API success");
    } catch (e) {
      dispatch(
        createCriticalLog("Into Cash API error", {
          responsePayload: { error: e },
        })
      );
      if (e?.status?.error) {
        props.onPaymentFail({
          message: e.status.userMessage,
          orderId: ORDER_ID,
        });
        props.goToMenu();
      }
    } finally {
      props.setLoading(false);
    }
  };

  useEffect(() => {
    onSave();
  }, []);

  return "";
}

CashPayment.propTypes = {};

export default CashPayment;
